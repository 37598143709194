<template>
  <div>
    <ui-text-editor v-bind="attrs" v-on="$listeners" />
  </div>
</template>
<script>
import { UiTextEditor } from 'redge-media-web-ui';
import { getLanguageCode } from 'redge-media-web-utils';
import i18n from '@/application/i18n';

export default {
  components: {
    UiTextEditor,
  },
  computed: {
    attrs() {
      const language = getLanguageCode(i18n.language, 2)?.toLowerCase();

      return { ...this.$attrs, language };
    },
  },
};
</script>
